<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20 6C8.95431 6 0 14.9543 0 26C0 37.0457 8.95431 46 20 46C31.0457 46 40 37.0457 40 26C40 14.9543 31.0457 6 20 6ZM12 19.1955C12 18.8952 12.1666 18.6196 12.4325 18.48L19.6245 14.7054C19.8596 14.5819 20.1404 14.5819 20.3755 14.7053L27.5675 18.48C27.8334 18.6196 28 18.8952 28 19.1955V36.0062C28 36.4524 27.6382 36.8142 27.192 36.8142H12.808C12.3618 36.8142 12 36.4524 12 36.0062V19.1955ZM18.248 32.3725C18.248 31.9262 18.6098 31.5645 19.0561 31.5645H20.9424C21.3887 31.5645 21.7504 31.9262 21.7504 32.3725V36.8124H18.248V32.3725ZM15.5561 21.7124C15.1098 21.7124 14.748 22.0742 14.748 22.5204C14.748 22.9667 15.1098 23.3285 15.5561 23.3285H24.4445C24.8908 23.3285 25.2526 22.9667 25.2526 22.5204C25.2526 22.0742 24.8908 21.7124 24.4445 21.7124H15.5561ZM14.748 26.5615C14.748 26.1152 15.1098 25.7534 15.5561 25.7534H24.4445C24.8908 25.7534 25.2526 26.1152 25.2526 26.5615C25.2526 27.0077 24.8908 27.3695 24.4445 27.3695H15.5561C15.1098 27.3695 14.748 27.0077 14.748 26.5615Z"
    fill="#545464"
  />
  <circle cx="36" cy="10" r="10" fill="white" />
  <circle cx="36" cy="10" r="8" fill="#06D6A0" />
  <path
    d="M35.5408 5.06637C35.7143 4.66339 36.2857 4.66339 36.4592 5.06637L37.5049 7.49443C37.5772 7.66252 37.7357 7.77763 37.9179 7.79453L40.5503 8.03867C40.9871 8.07919 41.1637 8.6226 40.8341 8.91218L38.848 10.6569C38.7105 10.7777 38.65 10.964 38.6902 11.1425L39.2714 13.7215C39.3679 14.1495 38.9057 14.4853 38.5284 14.2613L36.2553 12.9116C36.0979 12.8181 35.9021 12.8181 35.7447 12.9116L33.4716 14.2613C33.0943 14.4853 32.6321 14.1495 32.7286 13.7215L33.3098 11.1425C33.35 10.964 33.2895 10.7777 33.152 10.6569L31.1659 8.91218C30.8363 8.6226 31.0129 8.07919 31.4497 8.03867L34.0821 7.79453C34.2643 7.77763 34.4228 7.66252 34.4951 7.49443L35.5408 5.06637Z"
    fill="white"
  />
</template>
